// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";

import { sectionHeading } from "../../components/Typography/Headings";
import {
  DailyExpress,
  DailyMail,
  EveningStandard,
  TheSun,
  TheTelegraph,
  TheTimes,
  CityAM,
  TechCrunch,
} from "./publications";

const getPublicationLogo = (publication) => {
  switch (publication) {
    case "DailyMail":
      return <DailyMail />;
    case "DailyExpress":
      return <DailyExpress />;
    case "Telegraph":
      return <TheTelegraph />;
    case "Times":
      return <TheTimes />;
    case "Sun":
      return <TheSun />;
    case "EveningStandard":
      return <EveningStandard />;
    case "CityAM":
      return <CityAM />;
    case "TechCrunch":
      return <TechCrunch />;
    default:
      return <TheTimes />;
  }
};

const wrapper = css`
  display: none;
  ${media.tablet`
    display: block;
    text-align: center;
    margin: 40px 20px;
  `}
  ${media.desktop`
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 90px auto;
    max-width: 1140px;
  `}
`;

const h6styles = css`
  ${sectionHeading}
  ${media.desktop`
    margin: 0;
    width: calc(25% + 10px);
  `}
`;

const pressLogoWrapper = css`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  ${media.desktop`
  justify-content: space-between;
    width: calc(75% - 10px);
    margin-top: 0;
    flex-wrap: no-wrap;
  `}
`;

const linkStyles = css`
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 35px;
  display: inline-block;
  img {
    display: block;
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${media.desktop`
    margin-bottom: 0;
  `}
`;

type Props = {
  heading: string,
  repeat: {
    publication: string,
    link: PrismicLink,
  }[],
};

export const InThePress = ({ heading, repeat }: Props) => (
  <div css={wrapper}>
    <h6 css={h6styles}>{heading}</h6>
    <div css={pressLogoWrapper}>
      {repeat.map(({ publication, link }, index) => (
        <a href={link.url} css={linkStyles} target="_blank" key={index}>
          {getPublicationLogo(publication)}
        </a>
      ))}
    </div>
  </div>
);
