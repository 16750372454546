// @flow
export const DailyMail = () => (
  <img
    src="https://nested.imgix.net/marketing/press/daily_mail_hague.png"
    alt="Daily Mail logo"
    css="width: 146px;"
  />
);

export const DailyExpress = () => (
  <img
    src="https://nested.imgix.net/marketing/press/daily_express_hague.png"
    alt="Daily Express logo"
    css="width: 77px;"
  />
);

export const TheTelegraph = () => (
  <img
    src="https://nested.imgix.net/marketing/press/the_telegraph_hague.png"
    alt="The Telegraph logo"
    css="width: 126px;"
  />
);

export const TheTimes = () => (
  <img
    src="https://nested.imgix.net/marketing/press/the_times_hague.png"
    alt="The Times logo"
    css="width: 88px;"
  />
);

export const TheSun = () => (
  <img
    src="https://nested.imgix.net/marketing/press/the_sun_hague.png"
    alt="The Sun logo"
    css="width: 90px;"
  />
);

export const EveningStandard = () => (
  <img
    src="https://nested.imgix.net/marketing/press/evening_standard_hague.png"
    alt="Evening Standard logo"
    css="width: 90px;"
  />
);

export const CityAM = () => (
  <img
    src="https://nested.imgix.net/marketing/press/city_am_hague.png"
    alt="CityAM logo"
    css="width: 130px;"
  />
);

export const TechCrunch = () => (
  <img
    src="https://nested.imgix.net/marketing/press/techcrunch_hague.png"
    alt="TechCrunch logo"
    css="width: 65px;"
  />
);
